import React from "react";
import {
  Container,
  Button,
  Modal,
  Form,
  Spinner,
  Row,
  Col,
  InputGroup,
  Accordion,
  Alert,
} from "react-bootstrap";
import userDataStore from "../../store/userDataStore";
import { useOutletContext } from "react-router-dom";
import {
  _bgTemp,
  _changeAddedProductData,
  _createOrder,
  _getOrdersByStatus,
  _strRandom,
  _tempZoneSelection,
  _updateOrderStatusTwice,
  _updateSelectedOrder,
} from "../../utils/functions";
import ProductTable from "./ProductTable";
import axios from "axios";
import { orderStatusType } from "../../utils/definitions";
import Confirmation from "./modals/Confirmation";
import {
  AnomalyModalContent,
  CancelSlotModal,
  CancelValidation,
  CustomerServiceValidation,
  OperinModal,
} from "./modals/Modals";
import { SelectedOrderInfoBar } from "./SelectedOrderInfoBar";
import { _checkCountOrder } from "../../utils/orderFunctions";

const DeliveryDetail = ({ scanPageProps }: any) => {
  ////////////////////
  //Props & store
  ///////////////////

  const { setIsSelectedOrder, allSlot, setOrderReady } =
    useOutletContext<any>();

  const {
    selectedOrder,
    setSelectedOrder,
    newStatus,
    setOrderPickedUp,
    setOrderOperin,
    setOrderExpired,
    setSearchOrder,
    trigger,
    externalIdTab,
    setToastData,
    toggleShowAll,
  } = scanPageProps;

  const dataStore: any = userDataStore((states: any) => states);

  const [isErrorValid, setIsErrorValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingNewBasket, setIsLoadingNewBasket] =
    React.useState<boolean>(false);
  const [isUpdatable, setIsUpdatable] = React.useState<boolean>(false);
  const [countBasket, setCountBasket] = React.useState<number>(0);

  // const [errorMsg, setErrorMsg] = React.useState<string>("");

  const [isScroll, setIsScroll] = React.useState(false);
  const [newOrderData, setNewOrderData] = React.useState<any>({});
  const [multiOrderCode, setMultiOrderCode] = React.useState<any>(
    _strRandom("popopopp").toLocaleUpperCase()
  );

  const [selectedOrderProducts, setSelectedOrderProducts] = React.useState<any>(
    selectedOrder?.products
  );
  const [originOrderProducts, setOriginOrderProducts] = React.useState<any>(
    selectedOrder?.products
  );
  const [chosenProducts, setChosenProducts] = React.useState<any>([]);

  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Modal d'édition
  const [showOrderEditModal, setShowOrderEditModal] =
    React.useState<boolean>(false);
  const handleCloseOrderEditModal = () => setShowOrderEditModal(false);
  const handleShowOrderEditModal = () => setShowOrderEditModal(true);
  //Modal de confirmation de retrait
  const [showRetreiveModal, setShowRetreiveModal] =
    React.useState<boolean>(false);
  const handleCloseRetreiveModal = () => setShowRetreiveModal(false);
  const handleShowRetreiveModal = () => setShowRetreiveModal(true);

  //Modal de confirmation service client
  const [showCustomerService, setShowCustomerService] =
    React.useState<boolean>(false);
  const handleCloseCustomerService = () => setShowCustomerService(false);
  const handleShowCustomerService = () => setShowCustomerService(true);

  const [showCancel, setShowCancel] = React.useState<boolean>(false);
  const handleCloseCancel = () => setShowCancel(false);
  const handleShowCancel = () => {
    setShowCancel(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseCancel();
      setIsSelectedOrder(false);
    }, 2000);
  };

  //Modal de confirmation pour suppression d'un panier
  const [showCancelSlot, setShowCancelSlot] = React.useState<boolean>(false);
  const handleCloseCancelSlot = () => setShowCancelSlot(false);
  const handleShowCancelSlot = () => setShowCancelSlot(true);

  const [showAddSlot, setShowAddSlot] = React.useState<boolean>(false);
  const handleCloseAddSlot = () => setShowAddSlot(false);
  const handleShowAddSlot = () => setShowAddSlot(true);

  const [showUpdateStatus, setShowUpdateStatus] =
    React.useState<boolean>(false);
  const handleCloseUpdateStatus = () => setShowUpdateStatus(false);
  const handleShowUpdateStatus = () => {
    setShowUpdateStatus(true);

    setTimeout(() => {
      setSelectedOrder(null);
      handleCloseUpdateStatus();
      setIsSelectedOrder(false);
    }, 2000);
  };

  const indexOfOccurence =
    externalIdTab && (externalIdTab[selectedOrder?.externalOrderId] || 0);

  React.useEffect(() => {
    
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  React.useEffect(() => {
    //contruction du barcode du nouveau panier à partir du barcode du panier selectionné
    const barcode = selectedOrder?.barcode;
    const lastDashIndex = barcode?.lastIndexOf("-"); // trouve la position du dernier tiret dans le barcode
    const barcodeStart = barcode?.slice(0, lastDashIndex + 1); //extrait la partie du barcode jusqu'au dernier tiret inclus.
    const barcodeEnd = barcode?.slice(lastDashIndex + 1, -1); //extrait la partie du barcode après le dernier tiret jusqu'à l'avant dernier caractère inclus.
    // const newBarcode = barcodeStart + barcodeEnd + (indexOfOccurence + 1) //reconstruit le barcode en ajoutant 1 à la valeur de indexOfOccurence
    const newBarcode = barcodeStart + barcodeEnd + (countBasket + 1); //reconstruit le barcode en ajoutant 1 à la valeur de indexOfOccurence

    setNewOrderData({
      barcode: newBarcode,
      bookingSlot: selectedOrder?.bookingSlot?.["@id"],
      receiveCode: selectedOrder?.receiveCode,
      multiOrderCode: selectedOrder?.multiOrderCode
        ? selectedOrder?.multiOrderCode
        : multiOrderCode,
      hold: selectedOrder?.hold,
      products: [],
      ageRestriction: selectedOrder?.ageRestriction
        ? selectedOrder?.ageRestriction
        : null,
      externalOrderId: selectedOrder?.externalOrderId,
      client: {
        firstname: selectedOrder?.client?.firstname,
        lastname: selectedOrder?.client?.lastname,
        email: selectedOrder?.client?.email,
        phone: selectedOrder?.client?.phone,
      },
    });
  }, [countBasket]);

  React.useEffect(() => {
    setSelectedOrderProducts(selectedOrder?.products);
    setOriginOrderProducts(selectedOrder?.products);
  }, [showAddSlot]);

  React.useEffect(() => {
    //fonction qui vérifie si un panier de cette commande est déjà déposé dans le locker et de combien de panier est composée cette commande 
    _checkCountOrder(
      setIsLoadingNewBasket,
      dataStore,
      selectedOrder,
      setCountBasket,
      setIsUpdatable,
    )
  }, [selectedOrder]);

  // Function to manage the chosen products
  const handleCheckboxChange = (product: any) => {
    const isPresent = chosenProducts.some(
      (productToCheck: any) => productToCheck.name === product.name
    );
    const isPresent2 = selectedOrderProducts.some(
      (productToCheck: any) => productToCheck.name === product.name
    );

    if (isPresent) {
      if (!isPresent2) {
        const reputProduct = chosenProducts?.filter(
          (reprod: any) => reprod?.name !== selectedOrderProducts?.name
        )[0];
        console.log(reputProduct);

        selectedOrderProducts.push(reputProduct);
        setNewOrderData((prevData: any) => ({
          ...prevData,
          products: chosenProducts.filter((p: any) => p.name !== product?.name),
        }));
      } else {
        const updatedSelectedProducts = selectedOrderProducts?.map(
          (selectedProd: any) => {
            // Si l'ID du produit correspond à celui sélectionné, on le met à jour
            const prods = originOrderProducts?.filter(
              (prod: any) => prod?.name === selectedProd?.name
            )[0];
            if (selectedProd?.name === product?.name) {
              return {
                ...selectedProd,
                quantity: parseInt(selectedProd?.quantity) + prods?.quantity, // On applique les changements
              };
            }
            return selectedProd; // On retourne les autres produits inchangés
          }
        );
        setNewOrderData((prevData: any) => ({
          ...prevData,
          products: [chosenProducts.filter((p: any) => p !== product)],
        }));

        setSelectedOrderProducts(updatedSelectedProducts);
      }
      setChosenProducts(
        chosenProducts.filter((p: any) => p?.name !== product?.name)
      );
    } else {
      setChosenProducts([...chosenProducts, product]);

      const newTab = [...newOrderData.products, product];
      setNewOrderData((prevData: any) => ({
        ...prevData,
        products: newTab,
      }));

      const updatedSelectedProducts = selectedOrderProducts?.map(
        (selectedProd: any) => {
          // Si l'ID du produit correspond à celui sélectionné, on le met à jour
          const prods = originOrderProducts?.filter(
            (prod: any) => prod?.name === selectedProd?.name
          )[0];
          if (selectedProd?.name === product?.name) {
            return {
              ...selectedProd,
              quantity: parseInt(selectedProd?.quantity) - prods?.quantity, // On applique les changements
            };
            // }
          }
          return selectedProd; // On retourne les autres produits inchangés
        }
      );
      //retire de liste les produits à quantité 0
      const cleanProduct = updatedSelectedProducts?.filter(
        (prod: any) => prod?.quantity !== 0
      );

      // setSelectedOrderProducts(updatedSelectedProducts);
      setSelectedOrderProducts(cleanProduct);
    }
  };

  const changeStatus = (
    newStatus: orderStatusType,
    handleShowModal: () => void
  ) => {
    setIsLoading(true);

    let data = {
      status: newStatus,
      shippedBy: "api/users/" + dataStore.id,
    };
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_END_POINT + "orders/" + selectedOrder.id,
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: "Bearer " + dataStore.token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: any) => {
        _getOrdersByStatus(
          dataStore.token,
          "picked_up",
          setOrderPickedUp,
          setIsLoading
        );
        setIsLoading(false);
        handleCloseCustomerService();
        handleCloseCancelSlot();
        handleClose();

        handleShowModal();
      })
      .catch((error: any) => {
        console.log("update error");
        console.log(error);
        setIsErrorValid(true);
        setIsLoading(false);
      });
  };

  const message: string =
    "Voulez-vous vraiment déposer cette commande au service client ?";
  const CancelMessage: string = "Voulez-vous vraiment supprimer ce panier ?";


 
  const handleSubmitNewOrder2 = async (e: React.FormEvent<HTMLFormElement>) => {
    //bloque le rechargement de la page
    e.preventDefault();
    setIsLoading(true);
    try {
      //on vérifie si la commande est une commande multi
      // const multiOrderCodeValue = indexOfOccurence === 1 ? multiOrderCode : null;
      const multiOrderCodeValue = countBasket === 1 ? multiOrderCode : null;
      //on met à jour la commande d'origine
      const updateResponse: any = await _updateSelectedOrder(
        dataStore.token,
        selectedOrder.id,
        selectedOrderProducts,
        multiOrderCodeValue,
        setToastData,
        toggleShowAll
      );

      if (updateResponse.status === 200) {
        //crée une nouvelle commande avec les données du nouveau panier
        const responseCreate: any = await _createOrder(
          dataStore.token,
          newOrderData,
          setToastData,
          toggleShowAll
        );

        if (responseCreate?.status === 201) {
          //si la creation s'est bien déroulée on affiche un message
          setToastData((prev: any) => ({
            ...prev,
            bg: "success",
            message: "Nouveau panier ajouté",
            icon: "checkbox-circle",
          }));
          toggleShowAll();

          //récupère l'id de la nouvelle commande
          const newOrderId = responseCreate?.data.id;
          //on met à jour le statut de la nouvelle commande
          await _updateOrderStatusTwice(
            dataStore.token,
            newOrderId,
            dataStore.id
          );
        }
        _getOrdersByStatus(
          dataStore.token,
          "picked_up",
          setOrderPickedUp,
          setIsLoading
        );
        _getOrdersByStatus(
          dataStore.token,
          "ready_for_delivery",
          setOrderReady,
          setIsLoading
        );
      }
    } catch (error: any) {
      console.log(error);
      setToastData((prev: any) => ({
        ...prev,
        bg: "danger",
        message: "Une erreur s'est produite",
        icon: "error-warning",
      }));
      toggleShowAll();
    } finally {
      handleCloseAddSlot();
      handleCloseOrderEditModal();
      setSelectedOrder();
      setIsLoading(false);
    }
  };

  const productTableProps = {
    selectedOrder,
    handleShow,
    newStatus,
    trigger,
    handleShowRetreiveModal,
    handleShowCustomerService,
    handleShowCancelSlot,
    handleShowOrderEditModal,
    isLoadingNewBasket,
    isUpdatable,
  };
  const confirmationProps = {
    showCustomerService,
    handleCloseCustomerService,
    selectedOrder,
    changeStatus,
    newStatus,
    isLoading,
    message,
    handleShowUpdateStatus,
    handleShowCustomerService,
    setOrderExpired,
    setIsLoading,
    setSelectedOrder,
    setIsSelectedOrder,
  };
  const CancelSlotModalProps = {
    showCancelSlot,
    handleCloseCancelSlot,
    selectedOrder,
    changeStatus,
    newStatus,
    isLoading,
    CancelMessage,
    handleShowUpdateStatus,
    handleShowCancelSlot,
    setOrderPickedUp,
    setIsLoading,
    setSelectedOrder,
    setIsSelectedOrder,
    handleShowCancel,
  };
  const cancelValidationProps = { showCancel, handleCloseCancel };
  const CustomerServiceProps = { showUpdateStatus, handleCloseUpdateStatus };
  const operinModalProps = {
    selectedOrder,
    handleClose,
    setOrderPickedUp,
    setOrderOperin,
    setIsLoading,
    isLoading,
    setSelectedOrder,
    trigger,
  };
  const selectedOrderInfoBarProps = {
    isScroll,
    setSelectedOrder,
    setSearchOrder,
    setIsSelectedOrder,
    selectedOrder,
    trigger,
  };

  const anomalyModalContentProps = {
    setSelectedOrder,
    handleClose,
    setIsErrorValid,
  };

  //
  const filteredBooking = allSlot?.["hydra:member"]?.filter(
    (order: any) =>
      order?.company?.name === selectedOrder?.company?.name &&
      order?.slot?.temperatureZone?.locker?.shortLocation ===
        selectedOrder?.bookingSlot?.slot?.temperatureZone?.locker?.shortLocation
  );

  return (
    <Container fluid className="order-list pb-5">
      <div className="text-center">
        <p className="col-12 mb-0 text-center font-75 text-ui">
          Détail de la commande à livrer
        </p>
        <SelectedOrderInfoBar
          selectedOrderInfoBarProps={selectedOrderInfoBarProps}
        />
        <ProductTable productTableProps={productTableProps} />
      </div>

      <Confirmation confirmationProps={confirmationProps} />
      <CancelSlotModal CancelSlotModalProps={CancelSlotModalProps} />
      <CustomerServiceValidation CustomerServiceProps={CustomerServiceProps} />
      <CancelValidation cancelValidationProps={cancelValidationProps} />

      <Modal show={showAddSlot} onHide={handleCloseAddSlot} className="px-0">
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title>
            <i className="ri-add-line fs-2"></i> Ajouter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitNewOrder2}>
            <Accordion defaultActiveKey="1" alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Panier actuel</Accordion.Header>
                <Accordion.Body>
                  <Row className="border-bottom">
                    <Col xs={2} className="text-secondary text-end">
                      <strong>Qté</strong>
                    </Col>
                    <Col xs={8} className="text-secondary text-start">
                      <strong>Désignation</strong>
                    </Col>
                    <Col xs={2} className="text-secondary text-start">
                      <strong></strong>
                    </Col>
                  </Row>

                  {selectedOrderProducts?.map((prod: any, index: any) => (
                    <Row key={index} className="py-2 font-75">
                      <Col xs={2} className="text-secondary text-end">
                        {parseFloat(prod?.quantity)}
                      </Col>
                      <Col xs={10} className="text-secondary text-start">
                        {prod?.name}
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Nouveau panier</Accordion.Header>
                <Accordion.Body>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      const bookingSlotId = e?.currentTarget?.value;
                      setNewOrderData((prevData: any) => ({
                        ...prevData,
                        bookingSlot: bookingSlotId,
                      }));
                    }}
                    aria-label="zone"
                    className="border border-1 border-secondary my-2 text-ui-second "
                    required
                  >
                    <option value="" className="text-ui-second">
                      Température du Panier *
                    </option>
                    {filteredBooking?.map((lockers: any, index: any) => (
                      <option
                        key={index}
                        value={lockers && lockers["@id"]}
                        className={`text-ui-second ${_bgTemp(lockers)}`}
                        disabled={lockers.available < 1 ? true : false}
                      >
                        {_tempZoneSelection(lockers)} ({lockers?.slot.size}) -{" "}
                        {lockers?.available}{" "}
                        {lockers?.available > 1 ? "casiers" : "casier"} -{" "}
                        {lockers.slot.temperatureZone.locker.shortLocation}
                      </option>
                    ))}
                  </Form.Select>
                  {originOrderProducts?.map((prod: any, index: number) => (
                    <Row
                      key={index}
                      className={`py-2 ${
                        index % 2 === 0 && "table-row"
                      } border-1 border-gray border-bottom`}
                    >
                      <Col
                        xs={2}
                        className="text-dark font-85 border-1 border-dark"
                      >
                        <InputGroup
                          style={{ width: "40px" }}
                          className="mb- pe-0"
                        >
                          <Form.Control
                            id={`${Math.random()}`}
                            className="px-0 text-center border border-1 border-secondary"
                            type="number"
                            placeholder="Qté"
                            min={1}
                            max={
                              parseInt(
                                selectedOrder?.products?.[index]?.quantity
                              ) + 1
                            }
                            value={prod?.quantity || ""}
                            onChange={(e) => {
                              const qty = parseInt(e.currentTarget.value);

                              _changeAddedProductData(
                                originOrderProducts,
                                setOriginOrderProducts,
                                prod,
                                qty
                              );
                            }}
                            required
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        xs={8}
                        className="text-dark font-85 text-start m-auto  pe-0"
                      >
                        {prod?.name}{" "}
                      </Col>
                      <Col
                        xs={2}
                        className="text-dark font-85 text-start m-auto"
                      >
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id={`default-checkbox-${index}`}
                          value={prod}
                          onChange={() => handleCheckboxChange(prod)}
                          checked={chosenProducts.includes(prod)}
                          disabled={
                            selectedOrder?.products?.[index]?.quantity <
                            prod?.quantity
                              ? true
                              : false
                          }
                          required={chosenProducts?.length === 0 ? true : false}
                        />
                      </Col>
                      {selectedOrder?.products?.[index]?.quantity <
                        prod?.quantity && (
                        <Alert variant="danger" className="mt-2 font-75">
                          <i className="ri-error-warning-line"></i> Vous ne
                          devez pas dépasser la quantité d'origine :{" "}
                          {selectedOrder?.products?.[index]?.quantity}{" "}
                        </Alert>
                      )}
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Container className="mt-4">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Button
                    className="rounded-pill border-yellow bg-yellow text-success px-"
                    type="button"
                    onClick={() => {
                      handleCloseAddSlot();
                      setChosenProducts([]);
                      setSelectedOrderProducts([]);
                      setOriginOrderProducts([]);
                      setNewOrderData((prev: any) => ({
                        ...prev,
                        products: [],
                      }));
                    }}
                  >
                    <i className="ri-close-fill"></i>{" "}
                    <span className="">annuler</span>
                  </Button>
                </div>
                <div>
                  <Button
                    className="bg-green rounded-pill border-green text-light px-"
                    type="submit"
                    // onClick={handleShowOrderEditModal}
                  >
                    {isLoading ? (
                      <Spinner size="sm" as="span" />
                    ) : (
                      <span>
                        <i className="ri-check-fill"></i> Valider{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </Container>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showOrderEditModal}
        onHide={handleCloseOrderEditModal}
        className="px-0"
      >
         
            <Modal.Header className="border-bottom-0">
            <Modal.Title>
            <u>
              {" "}
              <i className="ri-shopping-basket-2-line fs-2"></i> Modifier la
              commande
            </u>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="pb-2">
            <div className="d-flex justify-content-between">
              <div className="text-start">
                <Button
                  className="bg-danger rounded-pill border-danger text-light"
                  type="button"
                  onClick={handleShowCancelSlot}
                >
                  <i className="ri-subtract-fill"></i>{" "}
                  <span className="">Supprimer</span>
                </Button>
              </div>
              <div>
                <Button
                  className="bg-dark rounded-pill border-dark text-light"
                  type="button"
                  onClick={handleShowAddSlot}
                  // disabled={selectedOrder?.products?.length < 2 ? true : false}
                >
                  <i className="ri-add-fill"></i> Ajouter
                </Button>
              </div>
            </div>
          </Container>
        </Modal.Body>
       

        <Modal.Footer className="py- px-">
          <Button
            className="bg-yellow rounded-pill border-yellow text-green px-3"
            type="button"
            onClick={handleCloseOrderEditModal}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {/*QrCode Modal & Error modal For Operin action*/}
      <Modal show={show} onHide={handleClose} className="px-0">
        {isErrorValid ? (
          <AnomalyModalContent
            anomalyModalContentProps={anomalyModalContentProps}
          />
        ) : (
          <OperinModal operinModalProps={operinModalProps} />
        )}
      </Modal>
    </Container>
  );
};

export default DeliveryDetail;
