import { OrdersType } from "../definitions/OrderType";
import { ToastDataType } from "../definitions/ToastType";
import OrdersService from "../service/Orders/OrdersService";
import { UserState } from "../store/userDataStore";


 //fonction qui vérifie si un panier de cette commande est déjà déposé dans le locker et de combien de panier est composée la commande sélectionnée 
export const _checkCountOrder = async (
  setIsLoadingNewBasket: React.Dispatch<React.SetStateAction<boolean>>,
  dataStore: UserState,
  selectedOrder: OrdersType,
  setCountBasket: React.Dispatch<React.SetStateAction<number>>,
  setIsUpdatable: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsLoadingNewBasket(true);
  setIsUpdatable(true);
  try {
    //ready_for_delivery & picked_up
    const response = await OrdersService.ordersForNewBasket(dataStore.token);
    //Operin
    const responseOperin = await OrdersService.ordersOperinForNewBasket(
      dataStore.token
    );
    const filteredOrder = response.data["hydra:member"].filter(
      (order: any) => order.externalOrderId === selectedOrder?.externalOrderId
    );
    const filteredOperinOrder = responseOperin.data["hydra:member"].filter(
      (order: any) =>
        order.externalOrderId === selectedOrder?.externalOrderId &&
        order.status === "operin"
    );
    //si un panier de cette commande est déjà déposé dans le locker, on bloque ajouter de nouveau panier
    if (filteredOperinOrder.length > 0) {
      //disabled le boutton d'ajout
      setIsUpdatable(true)
    } else {
      setIsUpdatable(false);
      setCountBasket(filteredOrder.length);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoadingNewBasket(false);
  }
};