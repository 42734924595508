import axios from 'axios'
import Swal from 'sweetalert2'
import { AuthStateType } from '../../pages/Public/Auth';

const API_URL = process.env.REACT_APP_END_POINT


class AuthService {
  login(
    userName: string,
    pass: string,
    setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>,
  ) {
    // setIsLoadingAuth(true);
    setAuthState((prev: AuthStateType) => ({
      ...prev,
      isLoadingAuth: true
    }))
    const data = JSON.stringify({
      username: userName,
      password: pass,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API_URL + "login_check",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        localStorage.setItem("user", response?.data?.token);
        // setToken(response.data.token);
        // setIsLoadingAuth(false);
        setAuthState((prev: AuthStateType) => ({
          ...prev,
          isLoadingAuth: false,
          token: response.data.token
        }))
      })
      .catch((error) => {
        console.log(error);
        // setMsg(
        //   error?.response?.data?.message
        //     ? error?.response?.data?.message
        //     : error?.message
        // );
        // setCodeError(
        //   error?.response?.data?.code !== undefined
        //     ? error?.response?.data?.code
        //     : ""
        // );
        // setIsError(true);
        // setIsLoadingAuth(false);
        setAuthState((prev: AuthStateType) => ({
          ...prev,
          isLoadingAuth: false,
          msgError: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
          codeError:  error?.response?.data?.code !== undefined
          ? error?.response?.data?.code
          : "Une erreur est survenue",
          isError: true
        }))
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(userName: string, pass: string) {
    return axios.post(API_URL + "register", {
      userName,
      pass,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user") || "{}");
  }

  forgotPassword(
    email: string,
    setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>,
  ) {

    const data = {
      email: email,
      url: "https://locker.app.easyclic.co/forgot-password",
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API_URL + "forgot-password",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(() => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Email de réinitilisation",
          text: "Envoi en cours",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .catch((error: any) => {
        console.log(error);
        setAuthState((prev: AuthStateType)=> ({
          ...prev,
          isError: true,
          codeError: error?.response?.status,
          msgError: error?.response?.data["hydra:description"]
            ? error?.response?.data["hydra:description"]
            : error?.response?.data?.message,

        }))
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService()
