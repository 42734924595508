import React from "react";
import { AuthStateType } from "../pages/Public/Auth";
import AuthService from "../service/Auth/AuthService";
import UserService from "../service/UserService";

export const _getMyData = async (
  token: string,
  setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>,
) => {
  try {
    const response = await UserService.me(token);
    setAuthState((prev: AuthStateType) => ({
      ...prev,
      myData: response.data,
    }));
  } catch (error: any) {
    console.error(error);

    setAuthState((prev: AuthStateType) => ({
      ...prev,
      isError: true,
      codeError: error?.response?.status,
      msgError: error?.response?.data?.message,
    }));

  }
};

export const _handleClearCache = () => {
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  } else {
    console.log(
      "Impossible de vider le cache. Votre navigateur ne prend pas en charge cette fonctionnalité."
    );
  }
};


export const _forgot = (
  email: string,
  setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>,
  handleClose: () => void,
) => {

  if (!email) {
    setAuthState((prev: AuthStateType)=> ({
      ...prev,
      isNotEmail: true,
    }));
  } else {
    AuthService.forgotPassword(email, setAuthState);
    handleClose();
  }
};