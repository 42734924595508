import React from "react";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { _coloredStatus, _getStatus } from "../../utils/functions";
import BadgedIcon from "../ui/BadgedIcon";
import "moment/locale/fr";
import age18 from "../../styles/alcool.webp";
import {
  PreparationIcon,
  RetreiveIcon,
  DeliveryIcon,
  OperinIcon,
} from "./icons/Icons";

interface itemListTypes {
  liv: {
    barcode: string | null;
    createdAt: string | null;
    status: string | null;
    cleveronSlotId: string | null;
    ageRestriction: number | null;
    multiOrderCode: string | null;
    bookingSlot: {
      slot: {
        temperatureZone: {
          name: string | null;
          locker: {
            shortLocation: string | null;
          };
        };
      };
    };
  };
  indx: number;
  setSelectedOrder: React.Dispatch<React.SetStateAction<any>>;
  setSearchOrder: React.Dispatch<React.SetStateAction<string>>;
  trigger: string;
  setIsSelectedOrder: React.Dispatch<React.SetStateAction<boolean>>;
  handleShowFullLocker: () => void;
  lockerFull: any;
  multiOrderCodes: any;
  setMultiOrderCodes: any;
}

// Fonction pour générer une couleur en dégradé
const generateGradientColor = (startColor: string, step: number) => {
  const color = parseInt(startColor.substring(1), 16);
  const newColor = (color + step) % 16777215; // Limiter la couleur dans la gamme hexadécimale
  return `#${newColor.toString(16).padStart(6, "0")}`;
};

const ItemList = ({
  liv,
  indx,
  setSelectedOrder,
  setSearchOrder,
  trigger,
  setIsSelectedOrder,
  handleShowFullLocker,
  lockerFull,
  multiOrderCodes,
  setMultiOrderCodes,
}: itemListTypes) => {
  const { multiOrderCode }: any = liv;

  const temperatureZoneNameToCheck =
    liv?.bookingSlot?.slot?.temperatureZone?.name;
  // Check if there is a locker with the same temperature zone as the current booking slot
  const isTemperatureZonePresent = lockerFull?.some((locker: any) => {
    // Get the name of the temperature zone for the current locker
    const lockerTemperatureZoneName = locker?.slot?.temperatureZone?.name;
    // Compare the temperature zone of the locker with the one to check
    return lockerTemperatureZoneName === temperatureZoneNameToCheck;
  });

  const [startColor, setStartColor] = React.useState("#6f42c1"); // Couleur de départ
  const [step, setStep] = React.useState(10000); // Pas de dégradé

  React.useEffect(() => {
    if (multiOrderCode !== undefined) {
      let color = multiOrderCodes[multiOrderCode];
      if (!color) {
        color = generateGradientColor(startColor, step);
        setStartColor(color); // Mettre à jour la couleur de départ pour le prochain dégradé
        setMultiOrderCodes({ ...multiOrderCodes, [multiOrderCode]: color });
        setStep(step + 11000); // Augmenter le pas pour le prochain dégradé
      }
    }
  }, [multiOrderCode, multiOrderCodes, setMultiOrderCodes, startColor, step]);

  const formattedDate = (dateStr: any) => {
    moment.locale("fr");
    const formattedDate = moment(dateStr).format("D MMM yy");
    return formattedDate;
  };

  const truncateCode = (liv?.barcode)?.slice(0, -4 ) + "****"

  return (
    <>
      <Container
        className={`list-item  px-0 rounded-3 mb-1`}
        style={{
          border: `2px solid ${
            multiOrderCodes[multiOrderCode]
              ? multiOrderCodes[multiOrderCode]
              : "#c5c5c5"
          }`,
          padding: "0px",
        }}
      >
        <Row
          className="m-auto"
          onClick={() => {
            if (isTemperatureZonePresent) {
              handleShowFullLocker();
            } else {
              setSearchOrder("");
              setSelectedOrder(liv);
              setIsSelectedOrder(true);
            }
          }}
        >
          {trigger === "history" ? (
            <Col xs={2} className=" m-auto p-0 ps- text-center ">
              <BadgedIcon
                slot={liv?.bookingSlot}
                borderColor="ui"
                imgSize="30px"
                componentSize="30px"
                design="indicator-small"
              />
            </Col>
          ) : trigger === "preparations" ? (
            <Col xs={1} className="m-auto py-0 text-start">
              <PreparationIcon />
            </Col>
          ) : trigger === "livraisons" ? (
            <Col xs={1} className="m-auto  py-0 text-start">
              <DeliveryIcon />
            </Col>
          ) : trigger === "operin" ? (
            <Col xs={2} className="m-auto  py-0 text-start">
              <OperinIcon />
            </Col>
          ) : (
            <Col xs={2} className="m-auto  py-0 text-start">
              <RetreiveIcon />
            </Col>
          )}
          <Col
            className={`text-ui text-start align-bottom m-auto pe-0 ps-4 ps-md-4   ${
              trigger !== "history" && ""
            }`}
          >
            <strong className="ff-agency font-75 text-ui ">
              {trigger === 'preparations' ? truncateCode : liv?.barcode}
            </strong>{" "}
            {trigger !== "history" && (
              <small className="font-65">
                {" "}
                - {formattedDate(liv?.createdAt)}
              </small>
            )}
            {trigger === "operin" && (
              <p className="mb-1">
                <span className={`text-${_coloredStatus(liv)} fw-bold font-65`}>
                  {_getStatus(liv?.status)}
                </span>{" "}
                - {""}
                <strong className="font-65 text-secondary">
                  slot id : {liv?.cleveronSlotId}
                </strong>
                {liv?.ageRestriction === 18 && (
                  <img src={age18} alt="+18ans" width={24} height={24} />
                )}
              </p>
            )}
            {(trigger === "history" || trigger === "operout") && (
              <p className="font-75 mb-0 ">
                <>
                  <span className={`text-${_coloredStatus(liv)} fw-bold`}>
                    {_getStatus(liv?.status)}
                  </span>{" "}
                  - {""}
                  <span className="text-secondary ">
                    {
                      liv?.bookingSlot?.slot?.temperatureZone?.locker
                        ?.shortLocation
                    }
                  </span>{" "}
                </>
              </p>
            )}
          </Col>
          <Col xs={3} md={2} lg={1} className="temp-size text-end py-0 m-auto">
            {trigger === "history" ? (
              <div className="">
                <small className="font-65">
                  {formattedDate(liv?.createdAt)}
                </small>
              </div>
            ) : (
              <BadgedIcon
                slot={liv?.bookingSlot}
                borderColor="ui"
                imgSize="30px"
                componentSize="30px"
                design="indicator-small"
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ItemList;
