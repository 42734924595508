import { AxiosError } from "axios";
import React from "react";
import BookingSlotservice from "../service/BookingSlot/BookingSlotservice";
import { BookingSlotCollectionType } from "../definitions/BookingSlotType";
import { OrderCollectionType } from "../definitions/OrderType";
import OrdersService from "../service/Orders/OrdersService";


export const _expiredToken = (
  error: unknown,
  errorState: {
    isError: boolean;
    message: string;
    isLoading: boolean;
    expireToken: boolean;
    isSelectedOrder: boolean;
  },
  setErrorState: React.Dispatch<
    React.SetStateAction<{
      isError: boolean;
      message: string;
      isLoading: boolean;
      expireToken: boolean;
      isSelectedOrder: boolean;
    }>
  >,
//   expireToken: any,
  authLogout: () => void,
//   setExpireToken: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (error instanceof AxiosError) {
    if (!errorState.expireToken) {
      if (error?.response?.data?.message === "Expired JWT Token") {
        // setExpireToken(true);
        setErrorState((prev) => ({
          ...prev,
          expired: true,
        }));
        alert("Session expirée, reconnectez-vous.");
        console.log("allOrder_app");
        authLogout();
        return;
      }
      if (error?.response?.data?.message === "Invalid JWT Token") {
        // setExpireToken(true);
        setErrorState((prev) => ({
          ...prev,
          expired: true,
        }));
        alert("Token invalide, reconnectez-vous.");
        authLogout();
        return;
      }
    }
  }
};


export const _getBookingAllSlot = async (
  token: string,
  expireToken: boolean,
  authLogout: () => void,
  setExpireToken: React.Dispatch<React.SetStateAction<boolean>>,
  setAllSlot: React.Dispatch<
    React.SetStateAction<BookingSlotCollectionType | undefined>
  >,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  errorState: {
    isError: boolean,
    message: string,
    isLoading: boolean,
    expireToken: boolean,
    isSelectedOrder: boolean},
  setErrorState: React.Dispatch<React.SetStateAction<{ 
    isError: boolean,
    message: string,
    isLoading: boolean,
    expireToken: boolean,
    isSelectedOrder: boolean,
  }>>
) => {
  try {
   
    setErrorState((prev) => ({...prev, isLoading: true})); //reset error state
    setIsLoading(true);
    const response = await BookingSlotservice.allSlot(token);
    setAllSlot(response.data);
  } catch (error: unknown) {
    console.error(error);
    _expiredToken(
      error,
      errorState,
      setErrorState,
      // expireToken,
      authLogout
      // setExpireToken
    );
  } finally {
    setIsLoading(false);
    setErrorState((prev) => ({...prev, isLoading: false})); //reset error state

  }
};




export  const _getOrdersByStatus = async (
    token: string,
    status: string,
    setData: React.Dispatch<React.SetStateAction<OrderCollectionType>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsError: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorState: React.Dispatch<React.SetStateAction<{   isError: boolean,
        message: string,
        isLoading: boolean,
        expireToken: boolean,
        isSelectedOrder: boolean,
      }>>
  ) => {
    // setIsLoading(true);
    setErrorState((prev) => ({...prev, isLoading: true})); //reset error state

    try {
      const response = await OrdersService.ordersByStatus(token, status);
      setData(response.data);
    } catch (error) {
      console.error(error);
    //   setIsError(true)
      setErrorState((prev) => ({...prev, isError: true})); //reset error state

    } finally {
    //   setIsLoading(false);
      setErrorState((prev) => ({...prev, isLoading: false})); //reset error state

    }
  };