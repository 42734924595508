import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import "./App.css";
import userDataStore, { UserState } from "./store/userDataStore";
import "animate.css";
import BottomNavBar from "./components/layout/BottomNavBar";
import { Alert, Toast, ToastContainer } from "react-bootstrap";
import { OrderCollectionType } from "./definitions/OrderType";
import { BookingSlotCollectionType } from "./definitions/BookingSlotType";
import {
  _getBookingAllSlot,
  _getOrdersByStatus,
} from "./utils/appFonction";



function App() {
  /////////////////////
  //States
  ////////////////////
  const authLogout = userDataStore((state: UserState) => state.authLogout);
  const token = userDataStore((state: UserState) => state.token);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [expireToken, setExpireToken] = React.useState<boolean>(false);
  const [isSelectedOrder, setIsSelectedOrder] = React.useState<boolean>(false);
  const [errorState, setErrorState] = React.useState<{
    isError: boolean,
    message: string,
    isLoading: boolean,
    expireToken: boolean,
    isSelectedOrder: boolean,
  }>({
    isError: false,
    message: "",
    isLoading: false,
    expireToken: false,
    isSelectedOrder: false,
  });

  const [idStore, setIdStore] = React.useState<number | null>(1);
  const [selectedStore, setSelectedStore] = React.useState<string>("");
  const [selectedOrderCity, setSelectedOrderCity] = React.useState<string>("");
  const [selectedItem, setSelectedItem] =
    React.useState<string>("preparations");
  const [allSlot, setAllSlot] = React.useState<BookingSlotCollectionType>();
  const [orderReady, setOrderReady] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );
  const [orderPickedUp, setOrderPickedUp] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );
  const [orderExpired, setOrderExpired] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );
  const [orderCreated, setOrderCreated] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );
  const [orderOperin, setOrderOperin] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );
  const [orderOperout, setOrderOperout] = React.useState<OrderCollectionType>(
    {} as OrderCollectionType
  );

  // const [historyOrder, setHistoryOrder] = React.useState<OrderCollectionType>();

  const [isOnline, setIsOnline] = React.useState<boolean>(
    window.navigator.onLine
  );

  const [showA, setShowA] = React.useState<boolean>(false);
  const toggleShowA = () => setShowA(!showA);

  /////////////////////
  //UseEffect
  ////////////////////
  React.useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
      alert("Connexion perdue, reconnectez-vous");
      authLogout();
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [authLogout, isOnline]);

  React.useEffect(() => {
    if (token && token?.length > 0) {
      _getBookingAllSlot(
        token,
        expireToken,
        authLogout,
        setExpireToken,
        setAllSlot,
        setIsLoading,
        errorState,
        setErrorState
      );
      _getOrdersByStatus(
        token,
        "ready_for_delivery",
        setOrderReady,
        setIsLoading,
        setIsError,
        setErrorState
      );
      _getOrdersByStatus(
        token,
        "picked_up",
        setOrderPickedUp,
        setIsLoading,
        setIsError,
        setErrorState
      );
      _getOrdersByStatus(
        token,
        "overtime",
        setOrderExpired,
        setIsLoading,
        setIsError,
        setErrorState
      );
    }
  }, [token]);

  React.useEffect(() => {
    setSelectedOrderCity(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker
            ?.shortLocation
        : ""
    );
    setSelectedStore(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker &&
            allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker["@id"]
        : ""
    );

    setIdStore(
      allSlot?.["hydra:member"]
        ? allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker &&
            allSlot?.["hydra:member"][0]?.slot?.temperatureZone?.locker.id
        : null
    );
  }, [allSlot]);

  /////////////////////
  //Props
  ////////////////////

  const bottomProps = {
    orderReady,
    orderPickedUp,
    orderExpired,
    setIsSelectedOrder,
  };

  return (
    <div data-bs-theme="light" className="bg-ui">
      {!token && <Navigate to="/connexion" />}
      <div className="w-100">
        <Outlet
          context={{
            idStore,
            setIdStore,
            setSelectedStore,
            setSelectedOrderCity,
            allSlot,
            setSelectedItem,
            selectedStore,
            selectedOrderCity,
            setAllSlot,
            orderReady,
            setOrderReady,
            orderPickedUp,
            setOrderPickedUp,
            orderExpired,
            setOrderExpired,
            orderCreated,
            setOrderCreated,
            selectedItem,
            expireToken,
            setExpireToken,
            isSelectedOrder,
            setIsSelectedOrder,
            orderOperin,
            setOrderOperin,
            orderOperout,
            setOrderOperout,
            toggleShowA,
            isLoading,
            isError,
            errorState, setErrorState
          }}
        />
      </div>
      <BottomNavBar
        selectedStore={selectedStore}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        bottomProps={bottomProps}
      />
      <ToastContainer
        position="top-end"
        className="mt-2 me-2"
        style={{ zIndex: 1500000 }}
      >
        <Toast show={showA} onClose={toggleShowA} delay={5000} autohide>
          <Alert
            variant="danger"
            className="p-3 mb-0 d-flex justify-content-start align-items-center"
          >
            <i className="ri-close-circle-line text-danger fs-3 me-2"></i>{" "}
            <strong className="">
              Cette commande contient de l'alcool et ne peut être traitée en
              dehors des heures légales !
            </strong>
          </Alert>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default App;
