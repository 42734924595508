import React from "react";
import { Alert, InputGroup, Modal, Form, Button } from "react-bootstrap";
import InfoAlert from "../warning/InfoAlert";
import { _forgot } from "../../../utils/authFunctions";
import { AuthStateType } from "../../../pages/Public/Auth";

interface ForgotPasswordModalType {
  show: boolean;
  handleClose: () => void;
  form: any;
  authState: AuthStateType,
  setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>
}

export default function ForgotPasswordModal({
  forgotPasswordModalProps,
}: {
  forgotPasswordModalProps: ForgotPasswordModalType;
}) {
  const {
    show,
    handleClose,
    form,
    authState,
    setAuthState,
  } = forgotPasswordModalProps;

  const email = authState?.myEmail;

  return (
    <Modal show={show} onHide={handleClose} centered className="rounded-0">
      <Modal.Header className="border-bottom-0">
        <Modal.Title>Mot de passe oublié</Modal.Title>
      </Modal.Header>
      <Form
        ref={form}
        onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
          e.preventDefault();
          _forgot(email, setAuthState, handleClose);
        }}
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="d-none">email</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="rounded-0 border">
                <i className="ri-at-line text-muted"></i>
              </InputGroup.Text>
              <Form.Control
                className=" rounded-0 border"
                type="email"
                name="user_email"
                placeholder="email"
                value={authState?.myEmail || ""}
                onChange={(e) => {
                  setAuthState((prev: AuthStateType) => ({
                    ...prev,
                    myEmail: e.target.value,
                  }));
                }}
              />
            </InputGroup>
            {authState.isNotEmail && (
              <Alert variant="danger" className="mt-2 py-0 text-cente">
                <InfoAlert
                  icon="ri-error-warning-line"
                  iconColor="danger"
                  message={"Saisissez votre email"}
                  fontSize="font-75"
                />
              </Alert>
            )}
          </Form.Group>
          <Alert
            variant="warning"
            className="border-2 border-warning py-1 rounded-0"
          >
            <i className="ri-information-line text-warning align-bottom"></i>{" "}
            <span className=" font-75 text-secondary mb-0">
              Suivez les instructions qui vous seront envoyées pour
              réinitialiser votre mot de passe.
            </span>
          </Alert>
        </Modal.Body>
        <Modal.Footer className="text-light border-top-0">
          <Button
            variant=""
            onClick={handleClose}
            className="text-success bg-yellow"
          >
            Fermer
          </Button>
          <Button type="submit" variant="" className="text-light bg-green">
            Envoyer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
