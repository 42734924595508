import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BadgedIcon from "./BadgedIcon";

export function SelectedOrderInfoBar({ selectedOrderInfoBarProps }: any) {
  const {
    isScroll,
    setSelectedOrder,
    setSearchOrder,
    setIsSelectedOrder,
    selectedOrder,
    trigger,
  } = selectedOrderInfoBarProps;
  const truncateCode = selectedOrder?.barcode?.slice(0, -4) + "****";

  return (
    <Container className="py-0 bg-gray rounded-pill  my-auto sticky-top ">
      <Row>
        <Col xs={2} md={1} lg={1} className="m-auto py-0">
          <i
            className={`ri-arrow-left-s-line 
         rounded-pill align-bottom
                   ${
                     !isScroll
                       ? "text-green  fs-3 fw-bold go-back"
                       : "text-ui fs-3 bg-gray fw-bold"
                   }`}
            onClick={() => {
              setSelectedOrder(undefined);
              setSearchOrder("");
              setIsSelectedOrder(false);
            }}
          ></i>
        </Col>
        <Col className=" m-auto text-ui-second text-center ps-1 pe-2 py-0">
          <span className="fw-bold font-85">
            <span className="fw-bold font-85 ff-agency">
              n°{" "}
              {trigger === "preparations"
                ? truncateCode
                : selectedOrder?.barcode}
            </span>
          </span>
        </Col>
        <Col xs={2} md={1} lg={1} className="m-auto me-3 py-2">
          <BadgedIcon
            slot={selectedOrder?.bookingSlot}
            borderColor="gray"
            imgSize="28px"
            componentSize="28px"
            design="indicator-small"
          />
        </Col>
      </Row>
    </Container>
  );
}
