import { Alert, Toast, ToastContainer } from "react-bootstrap";
import { ToastAllType } from "../../../definitions/ToastType";

export function ToastAll({toastAllProps}: {toastAllProps: ToastAllType}) {

    const {showAll, toggleShowAll, toastData} = toastAllProps;
  
    return (
      <ToastContainer
        containerPosition="fixed"
        position="top-center"
        className="mt-2 me-2"
        style={{ zIndex: 1500000 }}
      >
        <Toast show={showAll} onClose={toggleShowAll} delay={5000} autohide>
        
          <Alert
            variant={toastData?.bg}
            className="p-3 mb-0 d-flex justify-content-start align-items-center"
          >
            <i
              className={`ri-${toastData?.icon}-line fs-2 text-${toastData?.bg} me-2`}
            ></i>
            <div className="text-start">{toastData?.message}</div>
            <i onClick={toggleShowAll}
              className={`ri-close-line fs-2 text-secondary me-2 fixed-top text-end mt-2 `}
            ></i>
          </Alert>
        </Toast>
      </ToastContainer>
    );
  }