import {
  Card,
  Container,
  Alert,
} from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import userDataStore, { UserState } from "../../store/userDataStore";
import React, { useRef, useState } from "react";
import "../../App.css";
import AuthService from "../../service/Auth/AuthService";
import AuthForm from "../../components/ui/auth/AuthForm";
import useWebInstallPrompt from "../../hooks/useWebInstallPrompt";
import PwaInstallModal from "../../components/ui/modals/PwaInstallModal";
// @ts-ignore
import PWAPrompt from "react-ios-pwa-prompt";
import { _hasUserRole } from "../../utils/functions";
import ForgotPasswordModal from "../../components/ui/modals/ForgotPasswordModal";
import { _getMyData, _handleClearCache } from "../../utils/authFunctions";
import { UserDataType } from "../../definitions/UserDataType";

type Inputs = {
  userName: string;
  pass: string;
};
export type AuthStateType = {
  isLoadingAuth: boolean;
  isError: boolean;
  isView: boolean;
  msgError: string;
  codeError: string;
  isNotEmail: boolean;
  myEmail: string;
  isCompany: boolean;
  token: string;
  myData: UserDataType;
}


const Auth = () => {
  ////////////////////
  //Form States
  ///////////////////
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const form = useRef();

  ////////////////////
  //Store states
  ///////////////////
  const authLogin = userDataStore((state: UserState) => state.authLogin);
  const dataStore = userDataStore((state: UserState) => state);
  const userRoles = userDataStore((state: UserState) => state.roles);
  
  const hasSuperAdmin = _hasUserRole(userRoles, "ROLE_SUPER_ADMIN");
  const hasAdmin = _hasUserRole(userRoles, "ROLE_ADMIN");

  
    ////////////////////
    //Auth states
    ///////////////////

  const [authState, setAuthState] = React.useState<AuthStateType>({
    isLoadingAuth: false,
  isError: false,
  isView: false,
  msgError: "",
  codeError: "",
  isNotEmail: false,
  myEmail: "",
  isCompany: false,
  token: "",
  myData: {},
  } as AuthStateType);
  


  ///////////////////////
  //Forgot modal states
  //////////////////////
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const isAndroid = /Android/i.test(navigator.userAgent);

  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
    alert("Connexion perdue, reconnectez-vous");
  };
  ////////////////////
  //UseEffect
  ///////////////////

  React.useEffect(() => {
    //online offline
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    //modal install pwa
    const handleBeforeInstallPrompt: any = (event: any) => {
      event.preventDefault();
      if (event?.displayMode !== "browser") {
        if (isAndroid) {
          handleShowModal();
        }
      }
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    //clear the events
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
 
    
  }, []);


  React.useEffect(() => {
    if (authState?.token && authState?.token !== null) {
      _getMyData(
        authState?.token,
        setAuthState,
      );
    }
  }, [authState.token]);

  React.useEffect(() => {
    if (authState?.token && authState?.token!== null) {
   
      authLogin(
        true, // isLogged
        authState?.myData?.id, // user id
        authState?.myData?.firstName, // user name
        authState?.myData?.memberships ? authState?.myData?.memberships[0]?.id : null,
        authState?.myData?.memberships ? authState?.myData?.memberships[0]?.cleveronCompanyId : null, // cleveronCompany_Id
        hasAdmin || hasSuperAdmin
          ? "nocompany"
          : authState?.myData?.memberships
          ? authState?.myData?.memberships[0]?.name //company name
          : "",
          authState?.token, //token
        null, //subtoken
        authState?.myData?.apmAccessCode, //apm acces code
        authState?.myData?.roles, //roles
        authState?.myData?.username // username
      );
      _handleClearCache();
    }
  }, [authLogin, authState?.myData, authState?.token, hasAdmin, hasSuperAdmin]);

  React.useEffect(() => {
    if (dataStore.company_name === undefined) {
      setAuthState((prev: AuthStateType) => ({
        ...prev,
        isCompany: true,
        msgError:
          "Vous n'êtes affilié à aucune companie, contactez votre adminitrateur",
      }));
    }
  }, [dataStore]);

  React.useEffect(() => {
    if (authState?.myEmail) {
      setAuthState((prev: AuthStateType) => ({
        ...prev,
        isNotEmail: false
      }))
    }
  }, [authState?.myEmail]);



  const signUp: SubmitHandler<Inputs> = (dataz: Inputs, e: any) => {
    e.preventDefault();
    setAuthState((prev: AuthStateType) => ({
      ...prev,
      isError: false,
      msgError: "",
      codeError: "",
    }))
    AuthService.login(
      dataz.userName,
      dataz.pass,
      setAuthState,
    );
  };

  const formProps = {
    handleSubmit,
    register,
    errors,
    signUp,
    handleShow,
    authState,
    setAuthState
  };

  const pwaInstallModalProps = {
    showModal,
    handleCloseModal,
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
  };

  const forgotPasswordModalProps = {
    show,
    handleClose,
    form,
    authState,
    setAuthState,
  };

  return (
    <Container fluid className="auth-cont-sup px-0 py-0">
      <PwaInstallModal pwaInstallModalProps={pwaInstallModalProps} />
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
      <Container
        fluid
        className="auth-cont col-12 col-md-12 col-lg-6 px-0 bg-secondar"
      >
        {dataStore.token && dataStore.company_name && (
          <Navigate to="/preparations" />
        )}
        {authState?.isCompany && (
          <Alert variant="danger" className="text-center">
            {authState?.msgError}
          </Alert>
        )}
          <Card className="auth-form  bg-warni shadow animate__animated animate__fadeI rounded-0 border-0 vh-100">
            <Card.Body className="">
              <div className="logo-app text-center text-light animate__animated animate__rotateI"></div>
              <AuthForm formProps={formProps} />
            </Card.Body>
          </Card>
        <ForgotPasswordModal forgotPasswordModalProps={forgotPasswordModalProps} />
      </Container>
    </Container>
  );
};

export default Auth;
